import React, { useCallback, useContext, useEffect, useState } from "react";
import Tabs from "../Layout/Tabs";
import axios from "axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./styles/style.css";
import Meter from "./Components/Meter";
import VolumeChart from "./Components/VolumeChart";
import { Loader } from "../Loader/Loader";
import CardsDateFilter from "./Components/CardsDateFilter";
import ToastContext from "../Context/ToastContext";
import { useAuth } from "../Context/AuthContext";
import { format, subDays, startOfDay, endOfDay } from "date-fns";
import {
  formatLargeRecordsDataTo10Values,
  getCustomLabels,
  getCustomVolume,
  getDefaultVolume,
  getDefaultLabels,
  getFormattedNumber,
} from "./utils/Lib";
import { Link, useNavigate } from "react-router-dom";
import {
  M1_METER_NAME,
  M2_METER_NAME,
  TML_METER_NUMBERS,
  M3_METER_NAME,
  M4_METER_NAME,
  M5_METER_NAME,
  M6_METER_NAME,
  M7_METER_NAME,
  M8_METER_NAME,
  M9_METER_NAME,
  M10_METER_NAME,
  M11_METER_NAME,
  M12_METER_NAME,
  M13_METER_NAME,
  M14_METER_NAME,
  M15_METER_NAME,
  M17_METER_NAME,
  M18_METER_NAME,
  M20_METER_NAME,
  M21_METER_NAME,
  M23_METER_NAME,
  M24_METER_NAME,
  M26_METER_NAME,
  M27_METER_NAME,
  M28_METER_NAME,
  M29_METER_NAME,
  M30_METER_NAME,
  M31_METER_NAME,
  M32_METER_NAME,
  M33_METER_NAME,
  M34_METER_NAME,
  M35_METER_NAME,
  M36_METER_NAME,
  M39_METER_NAME,
  M40_METER_NAME,
  M41_METER_NAME,
  M42_METER_NAME,
  M43_METER_NAME,
  M45_METER_NAME,
  M46_METER_NAME,
  M50_METER_NAME,
  M51_METER_NAME,
  M49_METER_NAME,
  M54_METER_NAME,
  M55_METER_NAME,
  M56_METER_NAME,
  M57_METER_NAME,
  M58_METER_NAME,
  M59_METER_NAME,
  M62_METER_NAME,
  M63_METER_NAME,
  M61_METER_NAME,
  M37_METER_NAME,
  M38_METER_NAME,
} from "./utils/Constants";

const styles = {
  MainDiv: {
    width: "100vw",
    height: "100%",
    minHeight: "100vh",
  },

  card: {
    background: "#F9F9F9",
    height: "320px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  reportTableCard: {
    background: "#F9F9F9",
    height: "700px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  multiAxisChartCard: {
    background: "#F9F9F9",
    height: "455px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  parentCard: {
    background: "#F9F9F9",
    height: "360px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  Volume: {
    background: "#375EAD",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "31.5px",
  },
  FlowRate: {
    background: "#008000",
    width: "250px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  container: { padding: "40px" },
  column: { padding: "20px" },
  timeStampDiv: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  calenderIcon: { marginRight: "20px" },
  titleDiv: { width: "100%" },
  title: {
    marginLeft: "100px",
    color: "#375EAD",
    fontWeight: "bold",
  },
  flowRateDiv: {
    background: "#008000",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
  },
  flowRateVol: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "10px",
  },
  totalVolDiv: {
    background: "#375EAD",
    width: "280px",
    height: "24px",
    margin: "auto",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: "16px",
    textAlign: "center",
    marginTop: "12.5px",
  },
  reportTableCol: {
    padding: "20px",
    margin: "50px 0px",
  },
};

const PlantShopReport = () => {
  const { token } = useAuth();
  const { toast } = useContext(ToastContext);
  const URL = process.env.REACT_APP_URL;
  const [graphData, setGraphData] = useState({});
  const [loading, setLoading] = useState(true);
  const [avgFlowRate, setAvgFlowRate] = useState(0.0);

  const [dateFilter, setDateFilter] = useState({
    days: "",
    startdate: "",
    enddate: "",
  });
  const [volChartData, setVolChartData] = useState({});

  const handleCardDataChange = (data) => {
    setGraphData(data);
  };
  const handleLinechartDataChange = (data) => {
    setVolChartData(data);
  };
  const handleLoadingChange = () => {
    setLoading(false);
    toast.success("Data updated");
  };

  const fetchData = useCallback(async () => {
    let url = `${URL}getAllData?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });

      handleCardDataChange(res?.data?.flowRatesAndVolumeValues);
      handleLinechartDataChange(res?.data);
      setTimeout(() => {
        setLoading(false);
        toast.success("Data updated");
      }, 8000);
      graphData && volChartData && handleLoadingChange();
    } catch (err) {}
  }, [dateFilter]);

  useEffect(() => {
    fetchData();
  }, []);

  // function to get avrage for m4fr and m5fr whole values from given date
  const fetchAvgFlowRate = useCallback(async () => {
    let url = `${URL}avgFr-carplant?days=${dateFilter.days}&startDate=${dateFilter.startdate}&endDate=${dateFilter.enddate}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `abc ${token}`,
        },
      });
      setAvgFlowRate(response?.data?.avg.toFixed(2) || 0.0);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }, [dateFilter]);

  useEffect(() => {
    fetchAvgFlowRate();
  }, []);

  useEffect(() => {
    console.log("dateFilter", dateFilter);
    console.log("setTrue", setTrue);
  }, [dateFilter]);

  const setTrue =
    dateFilter.days.length ||
    dateFilter.startdate.length ||
    dateFilter.enddate.length;

  const customDateData = [
    {
      meter: 4,
      title: `${TML_METER_NUMBERS.M4} : ${M4_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M4FR),
      volume: getCustomVolume(graphData?.M4),
      labels: getCustomLabels(volChartData?.data?.M4),
      data:
        volChartData?.data?.M4 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M4),
    },
    {
      meter: 5,
      title: `${TML_METER_NUMBERS.M5} : ${M5_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M5FR),
      volume: getCustomVolume(graphData?.M5),
      labels: getCustomLabels(volChartData?.data?.M5),
      data:
        volChartData?.data?.M5 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M5),
    },
    {
      meter: 3,
      title: `${TML_METER_NUMBERS.M3} : ${M3_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M3FR),
      volume: getCustomVolume(graphData?.M3),
      labels: getCustomLabels(volChartData?.data?.M3),
      data:
        volChartData?.data?.M3 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M3),
    },
    {
      meter: 2,
      title: `${TML_METER_NUMBERS.M2} : ${M2_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M2FR),
      volume: getCustomVolume(graphData?.M2),
      labels: getCustomLabels(volChartData?.data?.M2),
      data:
        volChartData?.data?.M2 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M2),
    },
    //
    {
      meter: 51,
      title: `${TML_METER_NUMBERS.M51} : ${M51_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M51FR),
      volume: getCustomVolume(graphData?.M51),
      labels: getCustomLabels(volChartData?.data?.M51),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M51 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M51),
    },
    {
      meter: 1,
      title: `${TML_METER_NUMBERS.M1} : ${M1_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M1FR),
      volume: getCustomVolume(graphData?.M1),
      labels: getCustomLabels(volChartData?.data?.M1),
      data:
        volChartData?.data?.M1 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M1),
    },
    {
      meter: 6,
      title: `${TML_METER_NUMBERS.M6} : ${M6_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M6FR),
      volume: getCustomVolume(graphData?.M6),
      labels: getCustomLabels(volChartData?.data?.M6),
      data:
        volChartData?.data?.M6 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M6),
    },
    {
      meter: 50,
      title: `${TML_METER_NUMBERS.M50} : ${M50_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M50FR),
      volume: getCustomVolume(graphData?.M50),
      labels: getCustomLabels(volChartData?.data?.M50),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M50 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M50),
    },
    {
      meter: 33,
      title: `${TML_METER_NUMBERS.M33} : ${M33_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M33FR),
      volume: getCustomVolume(graphData?.M33),
      labels: getCustomLabels(volChartData?.data?.M33),
      data:
        volChartData?.data?.M33 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M33),
    },
    {
      meter: 32,
      title: `${TML_METER_NUMBERS.M32} : ${M32_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M32FR),
      volume: getCustomVolume(graphData?.M32),
      labels: getCustomLabels(volChartData?.data?.M32),
      data:
        volChartData?.data?.M32 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M32),
    },
    {
      meter: 34,
      title: `${TML_METER_NUMBERS.M34} : ${M34_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M34FR),
      volume: getCustomVolume(graphData?.M34),
      labels: getCustomLabels(volChartData?.data?.M34),
      //data: volChartData?.data?.M33?.map((item) => item?.M33TVDifference),
      data:
        volChartData?.data?.M34 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M34),
    },
    {
      meter: 35,
      title: `${TML_METER_NUMBERS.M35} : ${M35_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M35FR),
      volume: getCustomVolume(graphData?.M35),
      labels: getCustomLabels(volChartData?.data?.M35),
      //data: volChartData?.data?.M33?.map((item) => item?.M33TVDifference),
      data:
        volChartData?.data?.M35 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M35),
    },
    {
      meter: 36,
      title: `${TML_METER_NUMBERS.M36} : ${M36_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M36FR),
      volume: getCustomVolume(graphData?.M36),
      labels: getCustomLabels(volChartData?.data?.M36),
      //data: volChartData?.data?.M33?.map((item) => item?.M33TVDifference),
      data:
        volChartData?.data?.M36 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M36),
    },
    {
      meter: 39,
      title: `${TML_METER_NUMBERS.M39} : ${M39_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M39FR),
      volume: getCustomVolume(graphData?.M39),
      labels: getCustomLabels(volChartData?.data?.M39),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M39 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M39),
    },
    {
      meter: 15,
      title: `${TML_METER_NUMBERS.M15} : ${M15_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M15FR),
      volume: getCustomVolume(graphData?.M15),
      labels: getCustomLabels(volChartData?.data?.M15),
      data:
        volChartData?.data?.M15 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M15),
    },
    {
      meter: 58,
      title: `${TML_METER_NUMBERS.M58} : ${M58_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M58FR),
      volume: getCustomVolume(graphData?.M58),
      labels: getCustomLabels(volChartData?.data?.M58),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M58 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M58),
    },
    {
      meter: 21,
      title: `${TML_METER_NUMBERS.M21} : ${M21_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M21FR),
      volume: getCustomVolume(graphData?.M21),
      labels: getCustomLabels(volChartData?.data?.M21),
      data:
        volChartData?.data?.M21 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M21),
    },
    {
      meter: 18,
      title: `${TML_METER_NUMBERS.M18} : ${M18_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M18FR),
      volume: getCustomVolume(graphData?.M18),
      labels: getCustomLabels(volChartData?.data?.M18),
      data:
        volChartData?.data?.M18 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M18),
    },
    {
      meter: 20,
      title: `${TML_METER_NUMBERS.M20} : ${M20_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M20FR),
      volume: getCustomVolume(graphData?.M20),
      labels: getCustomLabels(volChartData?.data?.M20),
      data:
        volChartData?.data?.M20 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M20),
    },
    {
      meter: 17,
      title: `${TML_METER_NUMBERS.M17} : ${M17_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M17FR),
      volume: getCustomVolume(graphData?.M17),
      labels: getCustomLabels(volChartData?.data?.M17),
      data:
        volChartData?.data?.M17 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M17),
    },
    {
      meter: 62,
      title: `${TML_METER_NUMBERS.M62} : ${M62_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M62FR),
      volume: getCustomVolume(graphData?.M62),
      labels: getCustomLabels(volChartData?.data?.M62),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M62 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M62),
    },
    {
      meter: 24,
      title: `${TML_METER_NUMBERS.M24} : ${M24_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M24FR),
      volume: getCustomVolume(graphData?.M24),
      labels: getCustomLabels(volChartData?.data?.M24),
      data:
        volChartData?.data?.M24 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M24),
    },
    {
      meter: 23,
      title: `${TML_METER_NUMBERS.M23} : ${M23_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M23FR),
      volume: getCustomVolume(graphData?.M23),
      labels: getCustomLabels(volChartData?.data?.M23),
      data:
        volChartData?.data?.M23 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M23),
    },
    //
    {
      meter: 61,
      title: `${TML_METER_NUMBERS.M61} : ${M61_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M61FR),
      volume: getCustomVolume(graphData?.M61),
      labels: getCustomLabels(volChartData?.data?.M61),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M61 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M61),
    },
    {
      meter: 26,
      title: `${TML_METER_NUMBERS.M26} : ${M26_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M26FR),
      volume: getCustomVolume(graphData?.M26),
      labels: getCustomLabels(volChartData?.data?.M26),
      data:
        volChartData?.data?.M26 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M26),
    },
    {
      meter: 59,
      title: `${TML_METER_NUMBERS.M59} : ${M59_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M59FR),
      volume: getCustomVolume(graphData?.M59),
      labels: getCustomLabels(volChartData?.data?.M59),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M59 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M59),
    },
    {
      meter: 41,
      title: `${TML_METER_NUMBERS.M41} : ${M41_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M41FR),
      volume: getCustomVolume(graphData?.M41),
      labels: getCustomLabels(volChartData?.data?.M41),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M41 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M41),
    },
    {
      meter: 30,
      title: `${TML_METER_NUMBERS.M30} : ${M30_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M30FR),
      volume: getCustomVolume(graphData?.M30),
      labels: getCustomLabels(volChartData?.data?.M30),
      data:
        volChartData?.data?.M30 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M30),
    },
    {
      meter: 28,
      title: `${TML_METER_NUMBERS.M28} : ${M28_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M28FR),
      volume: getCustomVolume(graphData?.M28),
      labels: getCustomLabels(volChartData?.data?.M28),
      data:
        volChartData?.data?.M28 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M28),
    },
    {
      meter: 29,
      title: `${TML_METER_NUMBERS.M29} : ${M29_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M29FR),
      volume: getCustomVolume(graphData?.M29),
      // labels: label,
      // data: volChartData?.data?.M29.map((item) => item?.M29TVDifference),
      labels: getCustomLabels(volChartData?.data?.M29),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M29 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M29),
    },
    {
      meter: 27,
      title: `${TML_METER_NUMBERS.M27} : ${M27_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M27FR),
      volume: getCustomVolume(graphData?.M27),
      labels: getCustomLabels(volChartData?.data?.M27),
      data:
        volChartData?.data?.M27 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M27),
    },
    {
      meter: 40,
      title: `${TML_METER_NUMBERS.M40} : ${M40_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M40FR),
      volume: getCustomVolume(graphData?.M40),
      labels: getCustomLabels(volChartData?.data?.M40),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M40 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M40),
    },
    {
      meter: 49,
      title: `${TML_METER_NUMBERS.M49} : ${M49_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M49FR),
      volume: getCustomVolume(graphData?.M49),
      labels: getCustomLabels(volChartData?.data?.M49),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M49 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M49),
    },
    {
      meter: 55,
      title: `${TML_METER_NUMBERS.M55} : ${M55_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M55FR),
      volume: getCustomVolume(graphData?.M55),

      labels: getCustomLabels(volChartData?.data?.M55),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M55 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M55),
    },
    {
      meter: 46,
      title: `${TML_METER_NUMBERS.M46} : ${M46_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M46FR),
      volume: getCustomVolume(graphData?.M46),
      labels: getCustomLabels(volChartData?.data?.M46),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M46 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M46),
    },
    {
      meter: 42,
      title: `${TML_METER_NUMBERS.M42} : ${M42_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M42FR),
      volume: getCustomVolume(graphData?.M42),

      labels: getCustomLabels(volChartData?.data?.M42),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M42 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M42),
    },
    {
      meter: 63,
      title: `${TML_METER_NUMBERS.M63} : ${M63_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M63FR),
      volume: getCustomVolume(graphData?.M63),
      labels: getCustomLabels(volChartData?.data?.M63),
      data:
        volChartData?.data?.M63 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M63),
    },
    {
      meter: 31,
      title: `${TML_METER_NUMBERS.M31} : ${M31_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M31FR),
      volume: getCustomVolume(graphData?.M31),
      labels: getCustomLabels(volChartData?.data?.M31),
      data:
        volChartData?.data?.M31 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M31),
    },
    {
      meter: 45,
      title: `${TML_METER_NUMBERS.M45} : ${M45_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M45FR),
      volume: getCustomVolume(graphData?.M45),
      labels: getCustomLabels(volChartData?.data?.M45),
      // data: volChartData?.data?.M20?.map((item) => item?.M20TVDifference),
      data:
        volChartData?.data?.M45 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M45),
    },
    //M37 and M38 to be add here
    {
      meter: 37,
      title: `${TML_METER_NUMBERS.M37} : ${M37_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M37FR),
      volume: getCustomVolume(graphData?.M37),
      labels: getCustomLabels(volChartData?.data?.M37),
      data:
        volChartData?.data?.M37 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M37),
    },
    {
      meter: 38,
      title: `${TML_METER_NUMBERS.M38} : ${M38_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M38FR),
      volume: getCustomVolume(graphData?.M38),
      labels: getCustomLabels(volChartData?.data?.M38),
      data:
        volChartData?.data?.M38 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M38),
    },
    {
      meter: 57,
      title: `${TML_METER_NUMBERS.M57} : ${M57_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M57FR),
      volume: getCustomVolume(graphData?.M57),
      labels: getCustomLabels(volChartData?.data?.M57),
      data:
        volChartData?.data?.M57 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M57),
    },
    {
      meter: 43,
      title: `${TML_METER_NUMBERS.M43} : ${M43_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M43FR),
      volume: getCustomVolume(graphData?.M43),
      labels: getCustomLabels(volChartData?.data?.M43),
      data:
        volChartData?.data?.M43 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M43),
    },
    {
      meter: 56,
      title: `${TML_METER_NUMBERS.M56} : ${M56_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M56FR),
      volume: getCustomVolume(graphData?.M56),
      labels: getCustomLabels(volChartData?.data?.M56),
      data:
        volChartData?.data?.M56 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M56),
    },

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      meter: 7,
      title: M7_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M7FR),
      volume: getCustomVolume(graphData?.M7),
      labels: getCustomLabels(volChartData?.data?.M7),
      data:
        volChartData?.data?.M7 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M7),
    },
    {
      meter: 8,
      title: M8_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M8FR),
      volume: getCustomVolume(graphData?.M8),
      labels: getCustomLabels(volChartData?.data?.M8),
      data:
        volChartData?.data?.M8 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M8),
    },
    {
      meter: 9,
      title: M9_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M9FR),
      volume: getCustomVolume(graphData?.M9),
      labels: getCustomLabels(volChartData?.data?.M9),
      data:
        volChartData?.data?.M9 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M9),
    },
    {
      meter: 10,
      title: M10_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M10FR),
      volume: getCustomVolume(graphData?.M10),
      labels: getCustomLabels(volChartData?.data?.M10),
      data:
        volChartData?.data?.M10 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M10),
    },
    //
    {
      meter: 11,
      title: M11_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M11FR),
      volume: getCustomVolume(graphData?.M11),
      labels: getCustomLabels(volChartData?.data?.M11),
      data:
        volChartData?.data?.M11 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M11),
    },
    {
      meter: 12,
      title: M12_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M12FR),
      volume: getCustomVolume(graphData?.M12),
      labels: getCustomLabels(volChartData?.data?.M12),
      data:
        volChartData?.data?.M12 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M12),
    },
    {
      meter: 13,
      title: M13_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M13FR),
      volume: getCustomVolume(graphData?.M13),
      labels: getCustomLabels(volChartData?.data?.M13),
      data:
        volChartData?.data?.M13 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M13),
    },
    {
      meter: 14,
      title: M14_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M14FR),
      volume: getCustomVolume(graphData?.M14),
      labels: getCustomLabels(volChartData?.data?.M14),
      data:
        volChartData?.data?.M14 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M14),
    },
    {
      meter: 54,
      title: M54_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M54FR),
      volume: getCustomVolume(graphData?.M54),
      labels: getCustomLabels(volChartData?.data?.M54),
      data:
        volChartData?.data?.M54 &&
        formatLargeRecordsDataTo10Values(volChartData?.data?.M54),
    },
  ];

  const defaultData = [
    {
      meter: 4,
      title: `${TML_METER_NUMBERS.M4} : ${M4_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M4FR),
      volume: getDefaultVolume(graphData?.M4, volChartData?.data?.M4, "M4TV"),
      //volume: 600,
      labels: getDefaultLabels(volChartData?.data?.M4),
      data: volChartData?.data?.M4.map((item) => item?.M4TV),
    },
    {
      meter: 5,
      title: `${TML_METER_NUMBERS.M5} : ${M5_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M5FR),
      volume: getDefaultVolume(graphData?.M5, volChartData?.data?.M5, "M5TV"),
      labels: getDefaultLabels(volChartData?.data?.M5),
      data: volChartData?.data?.M5.map((item) => item?.M5TV),
    },

    {
      meter: 3,
      title: `${TML_METER_NUMBERS.M3} : ${M3_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M3FR),
      volume: getDefaultVolume(graphData?.M3, volChartData?.data?.M3, "M3TV"),
      labels: getDefaultLabels(volChartData?.data?.M3),
      data: volChartData?.data?.M3.map((item) => item?.M3TV),
    },
    {
      meter: 2,
      title: `${TML_METER_NUMBERS.M2} : ${M2_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M2FR),
      volume: getDefaultVolume(graphData?.M2, volChartData?.data?.M2, "M2TV"),
      labels: getDefaultLabels(volChartData?.data?.M2),
      data: volChartData?.data?.M2.map((item) => item?.M2TV),
    },
    {
      meter: 51,
      title: `${TML_METER_NUMBERS.M51} : ${M51_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M51FR),
      volume: getDefaultVolume(
        graphData?.M51,
        volChartData?.data?.M51,
        "M51TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M51),
      data: volChartData?.data?.M51?.map((item) => item?.M51TV),
    },
    {
      meter: 1,
      title: `${TML_METER_NUMBERS.M1} : ${M1_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M1FR),
      volume: getDefaultVolume(graphData?.M1, volChartData?.data?.M1, "M1TV"),
      labels: getDefaultLabels(volChartData?.data?.M1),
      data: volChartData?.data?.M1.map((item) => item?.M1TV),
    },
    {
      meter: 6,
      title: `${TML_METER_NUMBERS.M6} : ${M6_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M6FR),
      volume: getDefaultVolume(graphData?.M6, volChartData?.data?.M6, "M6TV"),
      labels: getDefaultLabels(volChartData?.data?.M6),
      data: volChartData?.data?.M6?.map((item) => item?.M6TV),
    },
    {
      meter: 50,
      title: `${TML_METER_NUMBERS.M50} : ${M50_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M50FR),
      volume: getDefaultVolume(
        graphData?.M50,
        volChartData?.data?.M50,
        "M50TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M50),
      data: volChartData?.data?.M50?.map((item) => item?.M50TV),
    },
    {
      meter: 33,
      title: `${TML_METER_NUMBERS.M33} : ${M33_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M33FR),
      volume: getDefaultVolume(
        graphData?.M33,
        volChartData?.data?.M33,
        "M33TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M33),
      data: volChartData?.data?.M33?.map((item) => item?.M33TV),
    },
    {
      meter: 32,
      title: `${TML_METER_NUMBERS.M32} : ${M32_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M32FR),
      volume: getDefaultVolume(
        graphData?.M32,
        volChartData?.data?.M32,
        "M32TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M32),
      data: volChartData?.data?.M32?.map((item) => item?.M32TV),
    },
    {
      meter: 34,
      title: `${TML_METER_NUMBERS.M34} : ${M34_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M34FR),
      volume: getDefaultVolume(
        graphData?.M34,
        volChartData?.data?.M34,
        "M34TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M34),
      data: volChartData?.data?.M34?.map((item) => item?.M34TV),
    },
    {
      meter: 35,
      title: `${TML_METER_NUMBERS.M35} : ${M35_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M35FR),
      volume: getDefaultVolume(
        graphData?.M35,
        volChartData?.data?.M35,
        "M35TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M35),
      data: volChartData?.data?.M35?.map((item) => item?.M35TV),
    },
    {
      meter: 36,
      title: `${TML_METER_NUMBERS.M36} : ${M36_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M36FR),
      volume: getDefaultVolume(
        graphData?.M36,
        volChartData?.data?.M36,
        "M36TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M36),
      data: volChartData?.data?.M36?.map((item) => item?.M36TV),
    },
    {
      meter: 39,
      title: `${TML_METER_NUMBERS.M39} : ${M39_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M39FR),
      volume: getDefaultVolume(
        graphData?.M39,
        volChartData?.data?.M39,
        "M39TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M39),
      data: volChartData?.data?.M39?.map((item) => item?.M39TV),
    },
    {
      meter: 15,
      title: `${TML_METER_NUMBERS.M15} : ${M15_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M15FR),
      volume: getDefaultVolume(
        graphData?.M15,
        volChartData?.data?.M15,
        "M15TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M15),
      data: volChartData?.data?.M15.map((item) => item?.M15TV),
    },
    {
      meter: 58,
      title: `${TML_METER_NUMBERS.M58} : ${M58_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M58FR),
      volume: getDefaultVolume(
        graphData?.M58,
        volChartData?.data?.M58,
        "M58TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M58),
      data: volChartData?.data?.M58?.map((item) => item?.M58TV),
    },
    {
      meter: 21,
      title: `${TML_METER_NUMBERS.M21} : ${M21_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M21FR),
      volume: getDefaultVolume(
        graphData?.M21,
        volChartData?.data?.M21,
        "M21TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M21),
      data: volChartData?.data?.M21?.map((item) => item?.M21TV),
    },
    {
      meter: 18,
      title: `${TML_METER_NUMBERS.M18} : ${M18_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M18FR),
      volume: getDefaultVolume(
        graphData?.M18,
        volChartData?.data?.M18,
        "M18TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M18),
      data: volChartData?.data?.M18.map((item) => item?.M18TV),
    },

    {
      meter: 20,
      title: `${TML_METER_NUMBERS.M20} : ${M20_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M20FR),
      volume: getDefaultVolume(
        graphData?.M20,
        volChartData?.data?.M20,
        "M20TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M20),
      data: volChartData?.data?.M20?.map((item) => item?.M20TV),
    },
    {
      meter: 17,
      title: `${TML_METER_NUMBERS.M17} : ${M17_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M17FR),
      volume: getDefaultVolume(
        graphData?.M17,
        volChartData?.data?.M17,
        "M17TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M17),
      data: volChartData?.data?.M17?.map((item) => item?.M17TV),
    },
    {
      meter: 62,
      title: `${TML_METER_NUMBERS.M62} : ${M62_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M62FR),
      volume: getDefaultVolume(
        graphData?.M62,
        volChartData?.data?.M62,
        "M62TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M62),
      data: volChartData?.data?.M62?.map((item) => item?.M62TV),
    },
    {
      meter: 24,
      title: `${TML_METER_NUMBERS.M24} : ${M24_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M24FR),
      volume: getDefaultVolume(
        graphData?.M24,
        volChartData?.data?.M24,
        "M24TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M24),
      data: volChartData?.data?.M24.map((item) => item?.M24TV) || 0,
    },
    //
    {
      meter: 23,
      title: `${TML_METER_NUMBERS.M23} : ${M23_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M23FR),
      volume: getDefaultVolume(
        graphData?.M23,
        volChartData?.data?.M23,
        "M23TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M23),
      data: volChartData?.data?.M23.map((item) => item?.M23TV) || 0,
    },
    {
      meter: 61,
      title: `${TML_METER_NUMBERS.M61} : ${M61_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M61FR),
      volume: getDefaultVolume(
        graphData?.M61,
        volChartData?.data?.M61,
        "M61TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M61),
      data: volChartData?.data?.M61?.map((item) => item?.M61TV),
    },
    {
      meter: 26,
      title: `${TML_METER_NUMBERS.M26} : ${M26_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M26FR),
      volume: getDefaultVolume(
        graphData?.M26,
        volChartData?.data?.M26,
        "M26TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M26),
      data: volChartData?.data?.M26?.map((item) => item?.M26TV),
    },
    {
      meter: 59,
      title: `${TML_METER_NUMBERS.M59} : ${M59_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M59FR),
      volume: getDefaultVolume(
        graphData?.M59,
        volChartData?.data?.M59,
        "M59TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M59),
      data: volChartData?.data?.M59?.map((item) => item?.M59TV),
    },
    {
      meter: 41,
      title: `${TML_METER_NUMBERS.M41} : ${M41_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M41FR),
      volume: getDefaultVolume(
        graphData?.M41,
        volChartData?.data?.M41,
        "M41TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M41),
      data: volChartData?.data?.M41?.map((item) => item?.M41TV),
    },
    {
      meter: 30,
      title: `${TML_METER_NUMBERS.M30} : ${M30_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M30FR),
      volume: getDefaultVolume(
        graphData?.M30,
        volChartData?.data?.M30,
        "M30TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M30),
      data: volChartData?.data?.M30?.map((item) => item?.M30TV),
    },
    {
      meter: 28,
      title: `${TML_METER_NUMBERS.M28} : ${M28_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M28FR),
      volume: getDefaultVolume(
        graphData?.M28,
        volChartData?.data?.M28,
        "M28TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M28),
      data: volChartData?.data?.M28.map((item) => item?.M28TV),
    },
    {
      meter: 29,
      title: `${TML_METER_NUMBERS.M29} : ${M29_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M29FR),
      volume: getDefaultVolume(
        graphData?.M29,
        volChartData?.data?.M29,
        "M29TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M29),
      data: volChartData?.data?.M29?.map((item) => item?.M29TV),
    },
    {
      meter: 27,
      title: `${TML_METER_NUMBERS.M27} : ${M27_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M27FR),
      volume: getDefaultVolume(
        graphData?.M27,
        volChartData?.data?.M27,
        "M27TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M27),
      data: volChartData?.data?.M27.map((item) => item?.M27TV),
    },
    {
      meter: 40,
      title: `${TML_METER_NUMBERS.M40} : ${M40_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M40FR),
      volume: getDefaultVolume(
        graphData?.M40,
        volChartData?.data?.M40,
        "M40TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M40),
      data: volChartData?.data?.M40?.map((item) => item?.M40TV),
    },
    {
      meter: 49,
      title: `${TML_METER_NUMBERS.M49} : ${M49_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M49FR),
      volume: getDefaultVolume(
        graphData?.M49,
        volChartData?.data?.M49,
        "M49TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M49),
      data: volChartData?.data?.M49?.map((item) => item?.M49TV),
    },
    {
      meter: 55,
      title: `${TML_METER_NUMBERS.M55} : ${M55_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M55FR),
      volume: getDefaultVolume(
        graphData?.M55,
        volChartData?.data?.M55,
        "M55TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M55),
      data: volChartData?.data?.M55?.map((item) => item?.M55TV),
    },
    {
      meter: 46,
      title: `${TML_METER_NUMBERS.M46} : ${M46_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M46FR),
      volume: getDefaultVolume(
        graphData?.M46,
        volChartData?.data?.M46,
        "M46TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M46),
      data: volChartData?.data?.M46?.map((item) => item?.M46TV),
    },
    {
      meter: 42,
      title: `${TML_METER_NUMBERS.M42} : ${M42_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M42FR),
      volume: getDefaultVolume(
        graphData?.M42,
        volChartData?.data?.M42,
        "M42TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M42),
      data: volChartData?.data?.M42?.map((item) => item?.M42TV),
    },
    {
      meter: 63,
      title: `${TML_METER_NUMBERS.M63} : ${M63_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M63FR),
      volume: getDefaultVolume(
        graphData?.M63,
        volChartData?.data?.M63,
        "M63TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M63),
      data: volChartData?.data?.M63?.map((item) => item?.M63TV) || 0,
    },
    {
      meter: 31,
      title: `${TML_METER_NUMBERS.M31} : ${M31_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M31FR),
      volume: getDefaultVolume(
        graphData?.M31,
        volChartData?.data?.M31,
        "M31TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M31),
      data: volChartData?.data?.M31?.map((item) => item?.M31TV),
    },
    {
      meter: 45,
      title: `${TML_METER_NUMBERS.M45} : ${M45_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M45FR),
      volume: getDefaultVolume(
        graphData?.M45,
        volChartData?.data?.M45,
        "M45TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M45),
      data: volChartData?.data?.M45?.map((item) => item?.M45TV),
    },
    {
      meter: 37,
      title: `${TML_METER_NUMBERS.M37} : ${M37_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M37FR),
      volume: getDefaultVolume(
        graphData?.M37,
        volChartData?.data?.M37,
        "M37TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M37),
      data: volChartData?.data?.M37?.map((item) => item?.M37TV),
    },
    {
      meter: 38,
      title: `${TML_METER_NUMBERS.M38} : ${M38_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M38FR),
      volume: getDefaultVolume(
        graphData?.M38,
        volChartData?.data?.M38,
        "M38TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M38),
      data: volChartData?.data?.M38?.map((item) => item?.M38TV),
    },
    {
      meter: 57,
      title: `${TML_METER_NUMBERS.M57} : ${M57_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M57FR),
      volume: getDefaultVolume(
        graphData?.M57,
        volChartData?.data?.M57,
        "M57TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M57),
      data: volChartData?.data?.M57?.map((item) => item?.M57TV),
    },
    {
      meter: 43,
      title: `${TML_METER_NUMBERS.M43} : ${M43_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M43FR),
      volume: getDefaultVolume(
        graphData?.M43,
        volChartData?.data?.M43,
        "M43TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M43),
      data: volChartData?.data?.M43?.map((item) => item?.M43TV),
    },
    {
      meter: 56,
      title: `${TML_METER_NUMBERS.M56} : ${M56_METER_NAME}`,
      flowRate: getFormattedNumber(graphData?.M56FR),
      volume: getDefaultVolume(
        graphData?.M56,
        volChartData?.data?.M56,
        "M56TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M56),
      data: volChartData?.data?.M56?.map((item) => item?.M56TV),
    },
    //////////////////////////////////////////////////////////////////////////////////////
    {
      meter: 7,
      title: M7_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M7FR),
      //volume: 517,
      volume: getDefaultVolume(graphData?.M7, volChartData?.data?.M7, "M7TV"),
      labels: getDefaultLabels(volChartData?.data?.M7),
      data: volChartData?.data?.M7?.map((item) => item?.M7TV),
    },
    {
      meter: 8,
      title: M8_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M8FR),
      volume: getDefaultVolume(graphData?.M8, volChartData?.data?.M8, "M8TV"),
      //volume: "--",
      labels: getDefaultLabels(volChartData?.data?.M8),
      data: volChartData?.data?.M8?.map((item) => item?.M8TV),
    },
    {
      meter: 9,
      title: M9_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M9FR),
      volume: getDefaultVolume(graphData?.M9, volChartData?.data?.M9, "M9TV"),
      //volume: "--",
      labels: getDefaultLabels(volChartData?.data?.M9),
      data: volChartData?.data?.M9?.map((item) => item?.M9TV),
    },
    {
      meter: 10,
      title: M10_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M10FR),
      volume: getDefaultVolume(
        graphData?.M10,
        volChartData?.data?.M10,
        "M10TV"
      ),
      labels: getDefaultLabels(volChartData?.data?.M10),
      data: volChartData?.data?.M10?.map((item) => item?.M10TV),
    },
    {
      meter: 11,
      title: M11_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M11FR),
      // volume: getDefaultVolume(
      //   graphData?.M11,
      //   volChartData?.data?.M11,
      //   "M11TV"
      // ),
      volume: 1940,
      labels: getDefaultLabels(volChartData?.data?.M11),
      data: volChartData?.data?.M11?.map((item) => item?.M11TV),
    },
    {
      meter: 12,
      title: M12_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M12FR),
      // volume: getDefaultVolume(
      //   graphData?.M12,
      //   volChartData?.data?.M12,
      //   "M12TV"
      // ),
      volume: 2103,
      labels: getDefaultLabels(volChartData?.data?.M12),
      data: volChartData?.data?.M12?.map((item) => item?.M12TV),
    },
    {
      meter: 13,
      title: M13_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M13FR),
      // volume: getDefaultVolume(
      //   graphData?.M13,
      //   volChartData?.data?.M13,
      //   "M13TV"
      // ),
      volume: 297,
      labels: getDefaultLabels(volChartData?.data?.M13),
      data: volChartData?.data?.M13?.map((item) => item?.M13TV),
    },
    {
      meter: 14,
      title: M14_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M14FR),
      // volume: getDefaultVolume(
      //   graphData?.M14,
      //   volChartData?.data?.M14,
      //   "M14TV"
      // ),
      volume: 243,
      labels: getDefaultLabels(volChartData?.data?.M14),
      data: volChartData?.data?.M14?.map((item) => item?.M14TV),
    },
    //
    {
      meter: 54,
      title: M54_METER_NAME,
      flowRate: getFormattedNumber(graphData?.M54FR),
      volume: getDefaultVolume(
        graphData?.M54,
        volChartData?.data?.M54,
        "M54TV"
      ),
      //volume: 911,
      labels: getDefaultLabels(volChartData?.data?.M54),
      data: volChartData?.data?.M54?.map((item) => item?.M54TV),
    },
    //
  ];

  const getAddition = (input1, input2, input3) => {
    const num1 = isNaN(Number(input1)) ? 0 : Math.round(Number(input1));
    const num2 = isNaN(Number(input2)) ? 0 : Math.round(Number(input2));
    const num3 = isNaN(Number(input3)) ? 0 : Math.round(Number(input3));
    return Math.round(num1 + num2 + num3);
  };

  const TotalVol =
    Math.round(graphData?.M4) +
    Math.round(graphData?.M5) +
    Math.round(graphData?.M10);

  const currentDate = new Date();
  const startDay =
    dateFilter.startdate.length === 0
      ? dateFilter.days.length === 0
        ? subDays(currentDate, 1)
        : subDays(currentDate, parseInt(dateFilter.days) + 1)
      : subDays(new Date(dateFilter.startdate), 0);
  const endDay =
    dateFilter.enddate.length === 0
      ? subDays(currentDate, 1)
      : subDays(new Date(dateFilter.enddate), 0);
  const startTime = format(startOfDay(startDay), "dd MMM yyyy ");
  const endTime = format(endOfDay(endDay), "dd MMM yyyy ");
  const Data = setTrue === 0 ? defaultData : customDateData;

  const array1 = volChartData?.data?.M4;
  const array2 = volChartData?.data?.M5;
  const array3 = volChartData?.data?.M10; // Add this line
  const maxLength = Math.max(array1?.length, array2?.length, array3?.length); // Update minLength

  // while (array1?.length < maxLength) {
  //   array1.push({ M4TV: null, M4TVDifference: null });
  // }

  // while (array2?.length < maxLength) {
  //   array2.push({ M5TV: null, M5TVDifference: null });
  // }

  // while (array3?.length < maxLength) {
  //   array2.push({ M10TV: null, M10TVDifference: null });
  // }

  const combinedArray = [];
  for (let i = 0; i < maxLength; i++) {
    const combinedItem = {
      defaultTotal:
        (array1[i] && array1[i] !== undefined ? array1[i]?.M4TV : 0) +
        (array2[i] && array2[i] !== undefined ? array2[i]?.M5TV : 0) +
        (array3[i] && array3[i] !== undefined ? array3[i]?.M10TV : 0), // Update this line
      customTotal:
        (array1[i] && array1[i] !== undefined ? array1[i]?.M4TVDifference : 0) +
        (array2[i] && array2[i] !== undefined ? array2[i]?.M5TVDifference : 0) +
        (array3[i] && array3[i] !== undefined ? array3[i]?.M10TVDifference : 0), // Update this line
    };
    combinedArray.push(combinedItem);
  }

  return (
    <div style={styles.MainDiv}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Tabs />
          <Container fluid style={styles.container}>
            <Row>
              <Col xs={12} md={12} style={styles.column}>
                <div style={styles.timeStampDiv}>
                  <img
                    src={require("../Assets/calendar.png")}
                    alt="clock"
                    height={25}
                    width={25}
                    style={styles.calenderIcon}
                  />
                  {(dateFilter.startdate === "" || dateFilter.enddate === "") &&
                  dateFilter.days.length === 0
                    ? ` ${startTime}`
                    : ` ${startTime} to ${endTime}`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <CardsDateFilter
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  fetchVoulme={fetchData}
                  fetchAvgFlowRate={fetchAvgFlowRate}
                />
              </Col>
              <Col
                md={2}
                xl={2}
                style={{
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Link to="/shopbilling/Car Plant">
                  <Button
                    style={{
                      borderRadius: "2.667px",
                      border: "0.667px solid #375EAD",
                      backgroundColor: "white",
                      color: "#375EAD",
                      fontWeight: "bold",
                      width: "146px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 20,
                      marginTop: "2.9%",
                      position: "absolute",
                      left: "76%",
                    }}
                  >
                    View Bill
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
                style={styles.column}
              >
                <div className="card" style={styles.parentCard}>
                  <>
                    <div style={styles.titleDiv}>
                      <div style={styles.title}> Car Plant Total</div>
                      <div className="Box">
                        <div>
                          <Meter
                            value={getFormattedNumber(avgFlowRate)}
                            width={320}
                            height={200}
                          />
                          <div style={styles.flowRateDiv}>
                            {getFormattedNumber(avgFlowRate)}
                          </div>
                          <div style={styles.flowRateVol}>
                            Flow Rate (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            /h)
                          </div>
                        </div>
                        <div>
                          <VolumeChart
                            width={350}
                            height={220}
                            Labels={
                              setTrue === 0
                                ? [
                                    "00:00",
                                    "06:00",
                                    "12:00",
                                    "18:00",
                                    "23:00",
                                    "00:00",
                                  ]
                                : getCustomLabels(volChartData?.data?.M10)
                            }
                            Data={combinedArray.map((item) =>
                              setTrue === 0
                                ? item?.defaultTotal
                                : item?.customTotal
                            )}
                          />
                          <div style={styles.totalVolDiv}>
                            {getAddition(
                              graphData?.M4,
                              graphData?.M5,
                              graphData?.M10
                            )}
                          </div>

                          <div style={styles.flowRateVol}>
                            Volume (
                            <span>
                              &nbsp;m<sup>3</sup>
                            </span>
                            )
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </Col>

              {Data?.map((item, index) => (
                <Col
                  sm={12}
                  xs={12}
                  md={12}
                  lg={12}
                  xl={6}
                  xxl={6}
                  style={styles.column}
                  key={index}
                >
                  <div className="card" style={styles.card}>
                    <>
                      <div style={styles.titleDiv}>
                        <div style={styles.title}>{item.title}</div>
                        <div className="Box">
                          <div>
                            <Link
                              to={`/live-dashboard/${item.meter}/${item.title}`}
                            >
                              <Meter
                                value={item.flowRate}
                                width={280}
                                height={160}
                              />
                            </Link>
                            <div style={styles.FlowRate}>{item.flowRate}</div>
                            <div style={styles.flowRateVol}>
                              Flow Rate (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              /h)
                            </div>
                          </div>
                          <div>
                            <VolumeChart
                              width={280}
                              height={160}
                              Labels={item.labels}
                              Data={item.data}
                            />
                            <div style={styles.Volume}>{item.volume}</div>

                            <div style={styles.flowRateVol}>
                              Volume (
                              <span>
                                &nbsp;m<sup>3</sup>
                              </span>
                              )
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

export default PlantShopReport;
